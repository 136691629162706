import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import NonStretchedImage from '../../../components/layout/NonStretchedImage';
import GetStarted from '../../../components/cta/GetStarted';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H3 from '@rotaready/frecl/build/H3';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import Text from '@rotaready/frecl/build/Text';
import Button from '@rotaready/frecl/build/Button';
import UL from '@rotaready/frecl/build/UL';
import TextListItem from '@rotaready/frecl/build/TextListItem';
import H1 from '@rotaready/frecl/build/H1';
import H4 from '@rotaready/frecl/build/H4';
import LinkWithArrow from '@rotaready/frecl/build/LinkWithArrow';

const isBrowser = typeof window !== 'undefined';

const Hero = styled.div`
  padding: 60px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0 100px 0;
  }
`;

const HeroInnerContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row-reverse;
    text-align: left;
    align-items: center;
  }
`;

const HeroBody = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    max-width: 540px;
    margin-left: 80px;
  }
`;

const HeroIllustration = styled.div`
  flex-grow: 1;

  .gatsby-image-wrapper {
    width: 100%;
    margin: 0 auto;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const BodyItem = styled.div`
  flex: 1 1 auto;
  margin-top: 40px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 0 1 45%;
    margin: 0;
  }
`;

const TextBlock = styled.div`
  margin-bottom: 20px;
`;

const HeroTextWrapper = styled.div`
  margin-bottom: 20px;
`;

const CtaButtons = styled.div`
  margin: 30px auto;
  max-width: 180px;

  button {
    width: 100%;
    margin: 0 0 20px 0;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-top: 40px;
    max-width: unset;

    button {
      width: unset;
      margin: 0 20px 0 0;
    }
  }
`;

const BenefitBlock = styled.div`
  padding: 40px;
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors.brand10};
  margin-bottom: 20px;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  constructor(props) {
    super(props);

    this.bodyTopRef = React.createRef();
  }

  goToForm = () => {
    const scrollTo = this.bodyTopRef.current.offsetTop;

    if (isBrowser) {
      window.scroll({ top: scrollTo, left: 0, behavior: 'smooth' });
    }
  };

  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="ICRTouch integration - Rotaready"
          description="A perfect pairing for hospitality: ICRTouch EPOS and Rotaready workforce management."
          url="integrations/icrtouch"
        />

        <ResponsiveContainer>
          <Hero>
            <HeroInnerContainer>
              <HeroBody>
                <HeroTextWrapper>
                  <H1 uistyle="brand160" text="ICRTouch and Rotaready" />
                </HeroTextWrapper>

                <HeroTextWrapper>
                  <H4 uistyle="grey">A perfect pairing for hospitality: ICRTouch EPOS and Rotaready workforce management.</H4>
                </HeroTextWrapper>

                <HeroTextWrapper>
                  <H4 uistyle="grey">Our free integration imports your ICRTouch hourly sales to display alongside your labour costs, helping you master your wage cost percentage.</H4>
                </HeroTextWrapper>

                <CtaButtons>
                  <Button uistyle="primary" text="Learn more" size="lg" onClick={this.goToForm} />

                  <a href="https://www.icrtouch.com" target="_blank" rel="noopener noreferrer">
                    <Button uistyle="primary" text="Visit ICRTouch" ghost borderless size="lg" />
                  </a>
                </CtaButtons>
              </HeroBody>

              <HeroIllustration>
                <NonStretchedImage fluid={data.heroImage.childImageSharp.fluid} />
              </HeroIllustration>
            </HeroInnerContainer>
          </Hero>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <BodyItem>
              <TextBlock>
                <H3 uistyle="brand160" text="Next-level cost control" />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="With our ICRTouch integration, there's no need to type your sales figures into Rotaready manually. They'll be imported automatically in near real-time and attributed to the correct site, department and stream." />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="Your net sales will appear in cost control, across various reports and on your rota in hourly time slots, alongside your wage costs, reservations, covers and the weather. Giving you everything you need to optimise your labour spend and make better informed decisions." />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="With the integration enabled, you can even let Rotaready forecast your sales for you, thanks to automatic analysis of your historical data." />
              </TextBlock>

              <TextBlock>
                <LinkWithArrow
                  direction="right"
                  text="Learn more about demand forecasting"
                  to="/demand-forecasting"
                  render={props => <Link {...props} />}
                />
              </TextBlock>

              <TextBlock>
                <H3 uistyle="brand160" text="Already a Rotaready customer?" />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="Setting up the integration is easy. Drop us a live chat or an email and our customer success team will guide you through it." />
              </TextBlock>
            </BodyItem>

            <BodyItem innerRef={this.bodyTopRef}>
              <TextBlock>
                <H3 uistyle="brand160" text="About ICRTouch" />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="ICRTouch offers more than Point of Sale. Manage all areas of your business with our family of software. Easy to use, reliable and fast EPOS solutions individually tailored to the way you work." />
              </TextBlock>

              <BenefitBlock>
                <TextBlock>
                  <UL size="lg" uistyle="normal" iconUistyle="primary">
                    <TextListItem text="TouchPoint is our flagship till software and provides effortless, reliable and proven service. Developed over 20 years and continually updated, TouchPoint remains on the cutting edge of hospitality and retail businesses." />
                    <TextListItem text="Specialising in software for the hospitality industry, retail, takeaways, schools and universities, entertainments and sports venues and more." />
                    <TextListItem text="Manage your business from anywhere in the world with TouchOffice Web, a real-time understanding of your business online." />
                    <TextListItem text="Add on extra software products and services to make the most of your ICRTouch solution and give your business a competitive edge." />
                  </UL>
                </TextBlock>
              </BenefitBlock>

              <TextBlock>
                <LinkWithArrow
                  direction="right"
                  text="Find out more about ICRTouch EPOS"
                  render={props => <a {...props} href="https://www.icrtouch.com" target="_blank" rel="noopener noreferrer" />}
                />
              </TextBlock>
            </BodyItem>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "integrations/icrtouch/hero-desktop.png" }) {
      childImageSharp {
        fluid(maxWidth: 512, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
  }
`;
